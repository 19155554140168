<template>
  <div className="global-loading-body">
      <div className="loader"></div>
    </div>
</template>
<script setup>
import {useRouter ,useRoute} from "vue-router";
import { onMounted ,getCurrentInstance } from "vue";
import {loginInThird} from "@utils/api/user";
import message from "@/utils/tool/message";
const route = useRoute();
const router = useRouter();
const {proxy} = getCurrentInstance()

const login = async(obj)=>{
  const {data,code,msg} = await loginInThird(obj)
  if(code === 10001) {
    router.push("/register?loginPhone=" + msg);
    return ;
  }
  if(code !== 0) {
    message.error(msg);
    router.push("/login");
    return;
  }
  message.success("登录成功")
  proxy.$cookies.set('userInfo', data, -1)
  localStorage.setItem('wyLoginToken_WYLG', data.token)
  router.push("/home");
}
onMounted(()=>{
  const data = route.query;
  data.oauthUrl = decodeURIComponent(data.oauthUrl);
  login(data)
})
</script>

<script>
export default {
  name: "LoginByCode"
}
</script>
<style lang="scss" src="./index.scss" scoped>
</style>
